@import url(https://fonts.googleapis.com/css?family=Montserrat:100,100italic,200,200italic,300,300italic,regular,italic,500,500italic,600,600italic,700,700italic,800,800italic,900,900italic);
@import url(https://fonts.googleapis.com/css?family=Lato:100,100italic,300,300italic,regular,italic,700,700italic,900,900italic);

*{
  font-family: Lato;

  --main-blue: #00FFF0;
}

h1, h2, h3, h4, h5{
  font-family: Montserrat;
}

.bg-gray{
  background-color: rgb(241, 241, 241);
}

footer{
  background-color: black;
  color: white;
}

.main-blue{
  color: var(--main-blue);
}

.cta{
  background-color: var(--main-blue);
  color: black;
  padding: 10px 100px;
  text-transform: uppercase;
}

.cta:hover{
  color: white;
  background-color: #00dfd0;
}