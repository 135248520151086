@import url(https://fonts.googleapis.com/css?family=Montserrat:100,100italic,200,200italic,300,300italic,regular,italic,500,500italic,600,600italic,700,700italic,800,800italic,900,900italic);
@import url(https://fonts.googleapis.com/css?family=Lato:100,100italic,300,300italic,regular,italic,700,700italic,900,900italic);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*{
  font-family: Lato;

  --main-blue: #00FFF0;
}

h1, h2, h3, h4, h5{
  font-family: Montserrat;
}

.bg-gray{
  background-color: rgb(241, 241, 241);
}

footer{
  background-color: black;
  color: white;
}

.main-blue{
  color: var(--main-blue);
}

.cta{
  background-color: var(--main-blue);
  color: black;
  padding: 10px 100px;
  text-transform: uppercase;
}

.cta:hover{
  color: white;
  background-color: #00dfd0;
}
.video{
    margin: 10px;
}
.error{
    color: red;
    font-size: .9em;
}
.next-class{
    font-weight: bolder;
}

.next-class-topic{
    font-weight: bold;
}
.bg-dark{
    background-color: black !important;
}

.logo{
    height: 5em;
}

.live_logo{
    height: 40px;
}
