.bg-dark{
    background-color: black !important;
}

.logo{
    height: 5em;
}

.live_logo{
    height: 40px;
}